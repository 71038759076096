<template>
  <div class="h-full bg-grey-100">
    <div class="flex flex-col text-dark-900 w-16 lg:w-56 h-full py-4 relative z-50" id="sidebar">
      <header class="px-4 lg:px-6 mb-4 lg:mb-0">
        <div class="flex items-center justify-center ">
          <div class="flex items-center lg:hidden">
            <img src="/assets/img/logo-notext.svg" alt="Logo" class="h-8">
          </div>
          <div class="items-center hidden lg:block">
            <img src="/assets/img/logo-light.svg" alt="Logo" class="h-8 w-auto">
          </div>
        </div>
      </header>
      <NavigationSidebarContent />
    </div>
  </div>
</template>

<script setup>
  import {storeToRefs} from "pinia";
  import {useUtilsStore} from "~/store/utils";

  const localePath = useLocalePath();
  const utilsStore = useUtilsStore();

  const {sidebarOpen, feedbackFormDialog} = storeToRefs(utilsStore);
  const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
  }


</script>

<style scoped>
@media screen and (max-width: 768px)
{
  .flyout-section {
    position: absolute;
    background-color: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    border-radius: 3px;
  }
  .flyout-section:after {
    right: 100%;
    top: 24px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #ffffff;
    border-width: 8px;
    margin-top: -8px;
  }
}
</style>